<template>
    <div>
        <v-layout pb-6 pr-6 pl-6 class="mainfont" wrap justify-center>
            <v-flex xs12 pt-4>
                <v-layout wrap justify-center>
                    <v-snackbar v-model="showSnackBar" color="black">
                        <v-layout wrap justify-center>
                            <v-flex text-left class="align-self-center">
                                <span style="color: white">{{ msg }}</span>
                            </v-flex>
                            <v-flex text-right>
                                <v-btn small :ripple="false" text @click="showSnackBar = false">
                                    <v-icon style="color: white">mdi-close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-snackbar>
                    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF"
                        color="#42275a" spinner="spinner" />
                </v-layout>
                <v-layout wrap justify-start>
                    <v-flex xs11 lg8 pt-8 text-left>
                        <span style="font-size: 25px; font-weight: bolder">BLOCK DATES</span>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 pt-4>
                <v-card>
                    <v-card-text>
                        <v-layout wrap justify-center>
                            <v-flex xs12>
                                <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field clearable outlined readonly hide-details="auto"
                                            v-model="formattedStartDate" v-bind="attrs" background-color="white" v-on="on"
                                            @click="menuFrom = !menuFrom" style="color: black">
                                            <template v-slot:append>
                                                <v-icon color="black">mdi-calendar</v-icon>
                                            </template>
                                            <template v-slot:label>
                                                <span class="custom-label-text" style="color: black; font-size: 14px">Block
                                                    Date From:</span>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="startDate" color="#13736f" @input="updateFormattedStartDate"
                                        :min="minDate"></v-date-picker>
                                </v-menu>
                            </v-flex>
                            <v-flex xs12 pt-10>
                                <v-menu ref="menuTo" v-model="menuTo" :close-on-content-click="false" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field clearable outlined readonly hide-details="auto"
                                            v-model="formattedEndDate" v-bind="attrs" background-color="white" v-on="on"
                                            @click="menuTo = !menuTo" style="color: black">
                                            <template v-slot:append>
                                                <v-icon color="black">mdi-calendar</v-icon>
                                            </template>
                                            <template v-slot:label>
                                                <span class="custom-label-text" style="color: black; font-size: 14px">Block
                                                    Date To:</span>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="endDate" color="#13736f" @input="updateFormattedEndDate"
                                        :min="startDate"></v-date-picker>
                                </v-menu>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="checkApprovedApplications" :disabled="!startDate || !endDate">
                            <span style="color: white;">CHECK APPROVED APPLICATIONS</span>
                        </v-btn>

                        <v-btn color="red" @click="showConfirmationDialog"
                            :disabled="!startDate || !endDate || !approvedApplicationsChecked">
                            <span style="color: white;">BLOCK DATE</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-if="approvedApplications.length > 0">
            <v-flex xs11>
                <v-card class="pa-4">
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-layout wrap>
                                <v-flex xs2 text-left>
                                    <span class="subheading" style="font-size: 16px">
                                        Applicant Name
                                    </span>

                                </v-flex>
                                <v-flex xs2 text-left>
                                    <span class="subheading" style="font-size: 16px">
                                        Disrict Name
                                    </span>

                                </v-flex>
                                <v-flex xs2 text-left>
                                    <span class="subheading" style="font-size: 16px">
                                        Duration of Camp
                                    </span>
                                </v-flex>
                                <v-flex xs2 text-left>

                                    <span class="subheading" style="font-size: 16px">
                                        Member Count
                                    </span>
                                </v-flex>
                                <v-flex xs2 text-left>

                                    <span class="subheading" style="font-size: 16px">
                                        Requested Date
                                    </span>
                                </v-flex>
                                <v-flex xs2 text-left>

                                    <span class="subheading" style="font-size: 16px">
                                        Approval Status
                                    </span>
                                </v-flex>

                            </v-layout>
                        </v-flex>
                        <v-divider></v-divider>
                        <v-flex xs12 v-for="(item, i) in approvedApplications" :key="i">
                            <v-layout wrap>
                                <v-flex xs12>

                                    <v-layout wrap justify-start>
                                        <v-flex pl-4 xs2 text-left>

                                            <br />
                                            <span>{{ item.applicantname }}</span>
                                        </v-flex>
                                        <v-flex xs2 text-left>

                                            <br />
                                            <span>{{ item.district }}</span>
                                        </v-flex>
                                        <v-flex xs2 text-left>

                                            <br />
                                            <span>{{ item.campDuration }}</span>
                                        </v-flex>


                                        <v-flex pl-4 xs2 text-left>

                                            <br />
                                            <span>{{ item.memberCount }}</span>
                                        </v-flex>
                                        <!-- <v-flex v-if="item.divisonApprovalStatus === 'Success' &&
                                            item.superAdminApprovalStatus !== 'rejected'
                                            " xs2 text-left>

                                            <br />
                                            <span style="color: red">{{
                                                formatDate(item.startDate)
                                            }}</span>
                                        </v-flex> -->
                                        <v-flex pl-4 xs2 text-left>

                                            <br />
                                            <span>{{
                                                formatDate(item.startDate)
                                            }}</span>
                                        </v-flex>
                                        <v-flex xs2 text-left>

                                            <br />
                                            <span style="color: red" v-if="item.divisonApprovalStatus === 'Success' &&
                                                    item.superAdminApprovalStatus === 'Success'
                                                    ">APPROVED</span>
                                            <span v-else>PENDING</span>
                                        </v-flex>
                                        <!-- <v-flex xs2 text-center pt-4>
                                            <v-btn @click="
                                            $router.push(
                                                '/CampDetailedDivision?id=' + item._id
                                            )
                                                " plain class="text-capitalize" color="blue">
                                                <v-icon color="blue">mdi-eye</v-icon>&nbsp;
                                                <span>VIEW DETAILS</span>
                                            </v-btn>
                                        </v-flex> -->
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>

                </v-card>
            </v-flex>


        </v-layout>
        <v-layout wrap justify-start pl-6 pt-6>
            <v-flex xs3 pb-3>
                <v-btn color="primary" @click="goBack">
                    <span style="text-transform: none; font-size: 15px; color: white;">GO BACK</span>
                </v-btn>
            </v-flex>
        </v-layout>
        <v-dialog v-model="confirmationDialog" max-width="600" persistent>
            <v-card>
                <v-card-title class="headline">Confirmation</v-card-title>
                <v-card-text>
                    Are you sure you want to block the selected date?
                    <v-text-field v-model="remarks" label="Remarks" outlined></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="blockDate" :disabled="!remarks.trim()">OK</v-btn>
                    <v-btn @click="confirmationDialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from "axios";
// import moment from "moment";
export default {
    data() {
        return {
            confirmationDialog: false,
            id: this.$route.query.id,
            deleteDialog: false,
            itemToDelete: null,
            formattedFromDate: null,
            formattedToDate: null,
            editDialog: false,
            editedFromDate: null,
            editedToDate: null,
            editedItem: null,
            itemArray2: ["Vegeterian", "Non-vegetarian"],
            curIdToDelete: null,
            name: null,
            editdialog: false,
            barlist: [],
            edit: [],
            userProfileTabs: null,
            subtab: null,
            appLoading: false,
            page: 1,
            keyArray: [],
            approvedApplications: [],
            dialogVisible: false,
            file: null,
            approvedApplicationsChecked: false,
            currentItemId: null,
            pages: 0,
            dialog1: false,
            preview: null,
            remarks: "",
            tab: null,
            startDate: null,
            endDate: null,
            barlist1: [],
            dialog3: false,
            deletedialog: false,
            minAmount: null,
            password: null,
            categoryarray: [],
            list: [],
            blockid: this.$route.query._id,
            flag: false,
            flag2: false,
            auctionbased: [],
            currentpage: 1,
            menu1: false,
            cdate: null,
            menu2: false,
            menu3: false,
            menu4: false,
            fromDate: new Date().toISOString().substr(0, 10),
            toDate: null,
            fromDate2: null,
            formattedEndDate: null,
            formattedStartDate: null,
            menuFrom: false,
            menuTo: false,
            toDate2: null,
            msg: null,
            currentPage: 1,
            showSnackBar: false,
            info: [],
            itemid: null,
            dialog2: false,
            viewdialog: false,

        };
    },
    computed: {
        minDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, "0");
            const day = String(today.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
        formattedDate() {
            const date = new Date(this.edit.selectedDate);
            return date.toLocaleString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        },
    },
    methods: {
        showConfirmationDialog() {
            this.confirmationDialog = true;
        },
        checkApprovedApplications() {
            axios({
                method: 'POST',
                url: `/divsion/blockdates`,
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    campid: this.id,
                    fromDate: this.startDate,
                    toDate: this.endDate,
                }
            })
                .then((response) => {
                    this.approvedApplications = response.data.data;
                    this.msg = response.data.msg;
                    this.showSnackBar = true;
                    this.approvedApplicationsChecked = true;
                    // const ids = this.approvedApplications.map(item => item._id);
                    // this.currentItemIds = ids;
                })
                .catch((response) => {
                    this.msg = response.data.msg;
                    this.showSnackBar = true;
                });
        },
        updateFormattedStartDate() {
            this.formattedStartDate = this.startDate ? this.formatDate(this.startDate) : null;
        },
        updateFormattedEndDate() {
            this.formattedEndDate = this.endDate ? this.formatDate(this.endDate) : null;
        },
        blockDate() {
            this.confirmationDialog = false;
            const remarks = this.remarks;
            if (this.startDate && this.endDate) {
                axios({
                    method: 'POST',
                    url: '/divsion/blockdates',
                    headers: {
                        token: localStorage.getItem("token"),
                    },
                    data: {
                        campid: this.id,
                        fromDate: this.startDate,
                        toDate: this.endDate,
                        status: true,
                        reason: remarks,
                        // itemIds: this.currentItemIds,
                    },
                })
                    .then((response) => {
                        console.log(response.data);
                        this.startDate = null;
                        this.endDate = null;
                        this.formattedStartDate = null;
                        this.formattedEndDate = null;
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        this.reserveDialog = false;
                        window.location.reload();
                    })
                    .catch((response) => {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    });
            } else {
                console.error('Please select both start date and end date.');
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const options = { year: "numeric", month: "long", day: "numeric" };
            const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
                date
            );
            return formattedDate;
        },
        formatForwardDate(dateString) {
            const date = new Date(dateString);
            const options = { hour: "numeric", minute: "numeric", hour12: true };
            const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
                date
            );
            return formattedDate;
        },
    },
};
</script>
<style>
.subheading {
    font-family: mainfont;
    color: rgb(13, 154, 69);
    text-transform: none;
    font-size: 17px;
}

.heading {
    font-family: mainfont;
    color: rgb(229, 111, 8);
    text-transform: none;
    font-size: 25px;
}
</style>